export const iOS = (userAgent: string) => !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
export const isMobileDevice = (navigator?: { userAgent?: string }) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent || '');
export const isWindowsDevice = (navigator?: { userAgent?: string }) => {
  const userAgent = navigator?.userAgent || '';
  return /Windows Phone|Windows NT/i.test(userAgent);
};

export const isMacOSDevice = (navigator?: { userAgent?: string }) => {
  const userAgent = navigator?.userAgent || '';
  return /Macintosh|Mac OS/i.test(userAgent);
};
export const API_URL = String(process.env.NEXT_PUBLIC_API_ENDPOINT);
export const LOCAL_ENV = process.env.NODE_ENV === 'development';
export const STAGING_ENV = !LOCAL_ENV && API_URL.includes('stg');
export const PRODUCTION_ENV = !STAGING_ENV && process.env.NODE_ENV === 'production';
export const CLIENT_URL = STAGING_ENV ? 'https://stg.flowerpot.ua' : 'https://flowerpot.ua';
export const ADMIN_URL = STAGING_ENV ? 'https://stg-admin.flowerpot.ua' : 'https://admin.flowerpot.ua';
export const GOOGLE_MAPS_API_KEY = String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY);

export const googlePlayAppLink = 'https://play.google.com/store/apps/details?id=ua.flowerpot.app&hl=uk'
export const appleStoreAppLink = 'https://apps.apple.com/am/app/flowerpot-%D0%B1%D1%83%D0%BA%D0%B5%D1%82%D0%B8-%D1%82%D0%B0-%D1%80%D0%BE%D1%81%D0%BB%D0%B8%D0%BD%D0%B8/id6443949660';