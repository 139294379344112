import dayjs from 'dayjs';

import {
  IAttributes,
  IImage,
  ILocation,
  ILocationDeliveryDay,
  IPlaceType,
  IProductAttributesDictionary,
  ISelectOption,
  IShippingTimeOption,
  IShippingTimeOptions
} from '@types';

export const sortAttributesByOrder =
  (attributes: IAttributes) => (a: IProductAttributesDictionary, b: IProductAttributesDictionary) => {
    if (attributes[a] && attributes[b]) {
      return (attributes[a]?.sortOrder || 0) - (attributes[b]?.sortOrder || 0);
    }
    return 0;
  };

export function changeWordEnding(number: number, word: string): string {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return number + ' ' + word;
  } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
    return number + ' ' + word + 'и';
  } else {
    return number + ' ' + word + 'ів';
  }
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  return /^\+?[1-9]\d{11,14}$/.test(phoneNumber);
}

export function formatLocationLabel(location?: ILocation | string): string {
  let label = '';
  if (typeof location !== 'string' && location?.city) {
    label += `м. ${location.city}`;
  }
  return label;
}

export function formatNumber(n: number | string): string {
  let num = Number(n);
  if (num < 0) throw new Error(`The input must be non-negative, but received: ${n}`);
  if (num === 0) return '0';
  const output: string[] = [];

  while (num >= 1000) {
    output.push(String((Math.round(num * 100) % 100000) / 100).padStart(3, '0'));
    num = Math.floor(num / 1000);
  }
  output.push(String(num));
  return output.reverse().join(' ');
}

export function roundNumber(number: unknown) {
  return +(Math.round(Number(number + 'e+2')) + 'e-2');
}

export const roundAndFormatNumber = (num: unknown) => formatNumber(roundNumber(num));

export const parseAddress = (value?: string | IPlaceType): string =>
  typeof value === 'string' ? value : value?.structured_formatting?.main_text || '';

export const getCheckoutLocationId = (location?: ILocation | string) => {
  if (!location) return '';
  if (typeof location === 'string') return location;
  if ('id' in location) {
    return location.id;
  }
};

export function transformTimeToOptions(
  timeSlots?: IShippingTimeOptions,
  availableOptions?: Partial<ILocationDeliveryDay>
) {
  return transformTimeSlotsToOptions('deliveryTimes', timeSlots, availableOptions);
}

export function transformExactTimeToOptions(
  timeSlots?: IShippingTimeOptions,
  availableOptions?: Partial<ILocationDeliveryDay>
) {
  return transformTimeSlotsToOptions('deliveryTimeSlots', timeSlots, availableOptions);
}

export function transformTimeSlotsToOptions(
  type: keyof ILocationDeliveryDay,
  timeSlots?: IShippingTimeOptions,
  availableOptions?: Partial<ILocationDeliveryDay>
): ISelectOption[] {
  if (!timeSlots || !availableOptions || !availableOptions[type]) return [];

  const deliveryTimeIds = availableOptions[type];
  if (!deliveryTimeIds || !Array.isArray(deliveryTimeIds) || !deliveryTimeIds.length) return [];

  return deliveryTimeIds
    .reduce(
      (acc: ISelectOption[], key: string) =>
        timeSlots[key] ? [...acc, { label: timeSlots[key].name, value: timeSlots[key].id }] : acc,
      []
    )
    .sort((a, b) => {
      if (b.label < a.label) {
        return 1;
      }
      if (b.label > a.label) {
        return -1;
      }
      return 0;
    });
}

export function filterExactTimeOptions({
  times,
  isTodaySelected,
  isNextDaySelected,
  nowHour // nowMinute
}: {
  times?: IShippingTimeOptions;
  isTodaySelected?: boolean;
  isNextDaySelected?: boolean;
  nowHour: number;
  nowMinute: number;
}): IShippingTimeOptions | Record<string, never> {
  if (!times) return {};

  try {
    const nowMoment = dayjs().add(3, 'hours');

    return Object.values(times)
      .filter((timeSlot) => {
        const [h, m] = timeSlot.name?.split(':'); // "17:45"

        if (isTodaySelected) {
          const timeSlotTime = dayjs().startOf('day').add(Number(h), 'hours').add(Number(m), 'minutes');

          /*
          console.log({
            now: nowMoment.toISOString(),
            slot: timeSlotTime.toISOString(),
            active: nowMoment.isBefore(timeSlotTime)
          })
          */

          return nowMoment.isBefore(timeSlotTime);
        } else if (isNextDaySelected && nowHour >= 21) {
          return Number(h) >= 11;
        }
        return true;
      })
      .reduce((acc, t) => ({ ...acc, [t.id]: t }), {});

    /*
    return Object.values(times).reduce((acc, t) => {
      if (isTodaySelected) {
        // if today selected then filter past timeSlots and 3 hours before now
        const [h, m] = t.name?.split(':');
        if (Number(h) < nowHour + 3) {
          return acc;
        } else if (Number(h) - (nowHour + 3) < 1 && Number(m) < nowMinute) {
          return acc;
        }
        return { ...acc, [t.id]: t };
      } else if (isNextDaySelected && nowHour >= 21) {
        // if next day selected then filter all timeSlots before 11:00
        const [h] = t.name?.split(':');
        if (Number(h) < 11) {
          return acc;
        }
        return { ...acc, [t.id]: t };
      }
      return { ...acc, [t.id]: t };
    }, {});
    */
  } catch (error) {
    console.log('failed to filter exact time options', error);
  }
  return {};
}

export function filterExactTimeOptionsForSelfPickUp({
  times,
  isTodaySelected,
  nowHour,
  nowMinute,
  workingHoursStart,
  workingHoursEnd
}: {
  times?: IShippingTimeOptions;
  isTodaySelected?: boolean;
  nowHour: number;
  nowMinute: number;
  workingHoursStart: string;
  workingHoursEnd: string;
}): IShippingTimeOptions | Record<string, never> {
  if (!times) return {};

  try {
    const [startHour, startMinute] = workingHoursStart.split(':').map(Number);
    const [endHour, endMinute] = workingHoursEnd.split(':').map(Number);
    return Object.values(times).reduce((acc, t) => {
      if (isTodaySelected) {
        // for today
        const [h, m] = t.name?.split(':').map(Number);
        if (
          // checked for available exact time
          h < nowHour ||
          (h === nowHour && m <= nowMinute) ||
          // checked for start hour and start minute shop
          h < startHour ||
          (h === startHour && m <= startMinute) ||
          // checked for end hour and end minute shop
          h > endHour ||
          (h === endHour && m <= endMinute) //
        ) {
          return acc;
        }
        return { ...acc, [t.id]: t };
      }
      return { ...acc, [t.id]: t }; // for next day available all exact times
    }, {});
  } catch (error) {
    console.log('failed to filter exact time options for self pick up', error);
  }
  return {};
}

export function filterTimePeriodsOptions({
  times,
  isTodaySelected // nowHour,
  // nowMinute
}: {
  times?: IShippingTimeOptions;
  isTodaySelected?: boolean;
  nowHour: number;
  nowMinute: number;
}): IShippingTimeOptions | Record<string, never> {
  if (!times) return {};

  const latestTimeToPick = dayjs().add(2, 'hours');

  try {
    return Object.values(times)
      .filter((timePeriod) => {
        if (!isTodaySelected) return true;
        // if today selected then filter time periods in the past and 2 hours before now
        const [_start, end = ''] = timePeriod.name?.split(' - '); // "17:00 - 20:00"
        const [eh, em] = end.split(':');
        const periodLatestTime = dayjs().startOf('day').add(Number(eh), 'hours').add(Number(em), 'minutes');

        // @todo review nowHour/nowMinute usage
        /*
        console.log('period', {
          period: timePeriod?.name,
          latestTimeToPick: latestTimeToPick.toISOString(),
          periodLatestTime: periodLatestTime.toISOString(),
          enable: latestTimeToPick.isBefore(periodLatestTime)
        })
        */
        return latestTimeToPick.isBefore(periodLatestTime);
      })
      .reduce((acc, t) => ({ ...acc, [t.id]: t }), {});
  } catch (error) {
    console.log('failed to filter exact time options', error);
  }
  return {};
}

export function shouldDisableExactTime(nowHour: number, times?: IShippingTimeOptions): boolean {
  if (times) {
    try {
      const lastTimeSlot = Object.values(times).pop() as IShippingTimeOption;
      if (lastTimeSlot) {
        const lastTimeSlotTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${lastTimeSlot?.name}:00`);
        // console.log({lastTimeSlotTime: lastTimeSlotTime.toString(), now: dayjs().add(3, 'hours').toString()})
        return dayjs().add(3, 'hours').isBefore(lastTimeSlotTime);
      }
      // return lastTimeSlot && Number(lastTimeSlot.name.split(':')[0]) - 3 <= nowHour;
    } catch (error) {
      console.log('failed to extract last exact time slot', error);
    }
  }
  return false;
}

export function shouldDisableTimePeriod(nowHour: number, times?: IShippingTimeOptions): boolean {
  if (times) {
    try {
      const timesValues = Object.values(times);
      const lastTimePeriod = timesValues[timesValues.length - 1];
      const [_start, end] = lastTimePeriod.name?.split(' - ');
      const [eh, _em] = end.split(':');
      return Number(eh) - 2 <= nowHour;
    } catch (error) {
      console.log('failed to extract last time period', error);
    }
  }
  return false;
}

export const getAddressValue = (address?: ILocation | IPlaceType) => {
  if (!address) return;
  try {
    if ('structured_formatting' in address && address.structured_formatting) {
      return address;
    }
    if (typeof address === 'string') {
      return {
        description: address,
        structured_formatting: {
          main_text_matched_substrings: [],
          main_text: address,
          secondary_text: ''
        },
        place_id: ''
      };
    } else if ('region' in address) {
      return {
        description: `${address.city} (${address.region})`,
        structured_formatting: {
          main_text_matched_substrings: [],
          main_text: address.city,
          secondary_text: ''
        },
        place_id: ''
      };
    }
  } catch (error) {
    console.error('Error setting address:', error);
  }
  return;
};

export function getRelAttribute(url?: string) {
  if (url && url.includes('?')) {
    return { rel: 'nofollow' };
  }
  return {};
}

export function getThumbnailImageUrl(image?: IImage) {
  return image?.thumbnail ? image.thumbnail : '/assets/images/flower-no-image.svg';
}


export const youtubeLinkRegex = /<a\s+[^>]*href="https:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})[^"]*"[^>]*>(.*?)<\/a>|<a\s+[^>]*href="https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})[^"]*"[^>]*>(.*?)<\/a>/g;

export const convertYouTubeLinksToIframes = (html: string) => {
  return html.replaceAll(youtubeLinkRegex, (match, videoId1, _, videoId2) => {
    const videoId = videoId1 || videoId2;
    return `<iframe src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  });
};

export const generateAltAndTitle = (fullName: string, displayCode: string) => {
  const alt = `Зображення товару ${fullName}, артикул: ${displayCode}`;
  const title = `${fullName}, артикул: ${displayCode}`;
  return { alt, title };
};

export const shouldShowAppLinkNotification = (): boolean => {
  if (typeof window === 'undefined') return false;
  return localStorage.getItem('appLinkNotification') !== 'true';
};