import ArrowClockwise from './svgs/arrow-clockwise.svg';
import ArrowExpand from './svgs/arrow-expand.svg';
import ArrowForward from './svgs/arrow-forward.svg';
import Bouquests from './svgs/bouquests.svg';
import CalendarChecked from './svgs/calendar-checked.svg';
import Calendar from './svgs/calendar.svg';
import CancelRounded from './svgs/cancel-rounded.svg';
import Cancel from './svgs/cancel.svg';
import CartChecked from './svgs/cart-checked.svg';
import CartEmpty from './svgs/cart-empty.svg';
import CartFilled from './svgs/cart-filled.svg';
import Cart from './svgs/cart.svg';
import Catalog from './svgs/catalog.svg';
import CheckRounded from './svgs/check-rounded.svg';
import CheckboxChecked from './svgs/checkbox-checked.svg';
import Checkbox from './svgs/checkbox.svg';
import CheckboxInter from './svgs/checkboxInter.svg';
import ChevronDown from './svgs/chevron-down.svg';
import ChevronLeft from './svgs/chevron-left.svg';
import ClockRounded from './svgs/clock-rounded.svg';
import CombinedOffers from './svgs/combined-offers.svg';
import Combo from './svgs/combo.svg';
import Community from './svgs/community.svg';
import Copy from './svgs/copy.svg';
import Cross from './svgs/cross.svg';
import DeleteBtn from './svgs/delete.svg';
import Delivery from './svgs/delivery.svg';
import Diameter from './svgs/diameter.svg';
import Discount from './svgs/discount.svg';
import Done from './svgs/done.svg';
import Edit from './svgs/edit.svg';
import EyeOff from './svgs/eye-off.svg';
import Eye from './svgs/eye.svg';
import FastDelivery from './svgs/fast-delivery.svg';
import Facebook from './svgs/fb.svg';
import FeedbackMessage from './svgs/feedback-message.svg';
import FilterSettings from './svgs/filter-settings.svg';
import Shape from './svgs/form-of-pot.svg';
import GeoLocation from './svgs/geo-location.svg';
import Google from './svgs/google.svg';
import Heart from './svgs/heart.svg';
import Height from './svgs/height.svg';
import Kashpo from './svgs/kashpo.svg';
import Logout from './svgs/logout.svg';
import Manufacturer from './svgs/manufacturer.svg';
import MasterCard from './svgs/mastercard.svg';
import Maturity from './svgs/maturity.svg';
import Minus from './svgs/minus.svg';
import More from './svgs/more.svg';
import PhoneContact from './svgs/phone-contact.svg';
import Phone from './svgs/phone.svg';
import PhotoCamera from './svgs/photocamera.svg';
import Plus from './svgs/plus.svg';
import Potted from './svgs/potted.svg';
import Profile from './svgs/profile.svg';
import Progress from './svgs/progress.svg';
import Quality from './svgs/quality.svg';
import Reply from './svgs/reply.svg';
import Return from './svgs/return.svg';
import Search from './svgs/search.svg';
import ServiceFeedBack from './svgs/service-feedback.svg';
import Size from './svgs/size.svg';
import SliderArrow from './svgs/slider-arrow.svg';
import Sort from './svgs/sort.svg';
import Successful from './svgs/successful.svg';
import Support from './svgs/support.svg';
import Telegram from './svgs/telegram.svg';
import Truck from './svgs/truck.svg';
import Coating from './svgs/type-of-coating.svg';
import Viber from './svgs/viber.svg';
import Visa from './svgs/visa.svg';
import Volume from './svgs/volume.svg';
import WaitPayment from './svgs/wait-payment.svg';
import Weight from './svgs/weight.svg';
import Whatsapp from './svgs/whatsapp.svg';
import Width from './svgs/width.svg';
import Info from './svgs/info.svg'
import LeftFeedback from './svgs/left-feedback.svg'
import AppStore from './svgs/appstore.svg';
import GooglePlay from './svgs/googleplay.svg';

export const ICONS = {
  cart: Cart,
  profile: Profile,
  whatsapp: Whatsapp,
  catalog: Catalog,
  geolocation: GeoLocation,
  phone: Phone,
  phoneContact: PhoneContact,
  search: Search,
  telegram: Telegram,
  viber: Viber,
  cross: Cross,
  eye: Eye,
  eyeOff: EyeOff,
  google: Google,
  facebook: Facebook,
  cartEmpty: CartEmpty,
  height: Height,
  heart: Heart,
  arrowExpand: ArrowExpand,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  sliderArrow: SliderArrow,
  checkbox: Checkbox,
  checkboxChecked: CheckboxChecked,
  checkboxInter: CheckboxInter,
  delivery: Delivery,
  support: Support,
  sort: Sort,
  calendar: Calendar,
  calendarChecked: CalendarChecked,
  discount: Discount,
  return: Return,
  cancel: Cancel,
  diameter: Diameter,
  manufacturer: Manufacturer,
  maturity: Maturity,
  quality: Quality,
  volume: Volume,
  weight: Weight,
  potted: Potted,
  combinedOffers: CombinedOffers,
  more: More,
  deleteBtn: DeleteBtn,
  checkRounded: CheckRounded,
  clockRounded: ClockRounded,
  cartChecked: CartChecked,
  cartFilled: CartFilled,
  arrowForward: ArrowForward,
  filterSettings: FilterSettings,
  truck: Truck,
  plus: Plus,
  minus: Minus,
  logout: Logout,
  waitPayment: WaitPayment,
  copy: Copy,
  arrowClockWise: ArrowClockwise,
  visa: Visa,
  masterCard: MasterCard,
  community: Community,
  progress: Progress,
  successful: Successful,
  kashpo: Kashpo,
  fastDelivery: FastDelivery,
  bouquests: Bouquests,
  combo: Combo,
  done: Done,
  serviceFeedBack: ServiceFeedBack,
  reply: Reply,
  feedbackMessage: FeedbackMessage,
  width: Width,
  size: Size,
  coating: Coating,
  shape: Shape,
  photocamera: PhotoCamera,
  edit: Edit,
  cancelRounded: CancelRounded,
  info: Info,
  leftFeedback: LeftFeedback,
  appStore: AppStore,
  googlePlay: GooglePlay
};
