import { Metadata } from 'next';
import dayjs from 'dayjs';

import { IBreadCrumbLink } from '@components/shared/breadcrumbs/Breadcrumbs';
import { STATUS_AVAILABILITY, STATUS_LABELS } from '@lib/constants/common-constants';
import { CLIENT_URL } from '@lib/constants/environment';
import { ICountriesMap } from '@lib/countriesMap';
import {
  AccumulatorType,
  IAttribute,
  IAttributes,
  ICartItem,
  ICheckoutData,
  IGAMAddPaymentInfoPayload,
  IGAMAddShippingInfoPayload,
  IGAMAddToCartPayload,
  IGAMBeginCheckoutPayload,
  IGAMProductPayload,
  IGAMPurchasePayload,
  IGAMRemoveFromCartPayload,
  IGAMViewItemPayload,
  IOrder,
  IProduct,
  ITrackTypePurchase
} from '@types';
import { GAMStorageService } from './GAMStorageService';

function getCategoriesTree(list: Array<string>): Array<string> {
  if (list && Array.isArray(list) && list.length) {
    if (list[0] === 'Каталог') {
      return list.slice(1);
    }
    return list;
  }
  return [];
}

export function transformPurchasePayload(order: IOrder, type: ITrackTypePurchase): IGAMPurchasePayload | undefined {
  if (!order || !order.items || !Array.isArray(order.items)) return;
  try {
    const trackProductData = GAMStorageService.getFromStore();
    const { items, value } = order.items.reduce<AccumulatorType>(
      (acc, item) => {
        const [item_category, item_category2, item_category3] = getCategoriesTree(item.flatProduct.parentCategories);
        const productPayload: IGAMProductPayload = {
          item_name: item.flatProduct.displayName,
          item_id: item.flatProduct.displayCode,
          price: item.flatProduct.specialPrice || item.flatProduct.price,
          item_category,
          item_category2,
          item_category3,
          quantity: item.qty,
          availability: STATUS_LABELS[item.flatProduct.inStockStatus],
          item_list_name:
            trackProductData && trackProductData[item.flatProduct.id]
              ? trackProductData[item.flatProduct.id]?.item_list_name
              : 'product',
          index:
            trackProductData && trackProductData[item.flatProduct.id] ? trackProductData[item.flatProduct.id]?.index : 1
        };
        return {
          items: [...acc.items, productPayload],
          value: acc.value + item.total
        };
      },
      { items: [], value: 0 }
    );

    return {
      items,
      value,
      currency: typeof order.currency !== 'string' ? order?.currency?.alias?.toUpperCase() : 'UAH',
      transaction_id: order.orderId,
      type,
      shipping: order.shippingAmount
    };
  } catch (error) {
    console.error('Error in transformPurchasePayload:', error);
  }
}

export function transformViewItemPayload(product: IProduct): IGAMViewItemPayload | undefined {
  if (!product) return;
  try {
    const trackProductData = GAMStorageService.getFromStore();
    const [item_category, item_category2, item_category3] = getCategoriesTree(product.parentCategories);
    const item: IGAMProductPayload = {
      item_name: product.displayName,
      item_id: product.displayCode,
      price: product.specialPrice || product.price,
      item_category,
      item_category2,
      item_category3,
      availability: STATUS_LABELS[product.inStockStatus],
      item_list_name:
        trackProductData && trackProductData[product.id] ? trackProductData[product.id]?.item_list_name : 'product',
      index: trackProductData && trackProductData[product.id] ? trackProductData[product.id]?.index : 1
    };
    return { items: [item] };
  } catch (error) {
    console.error('Error in transformViewItemPayload:', error);
  }
}

export function transformRemoveFromCartPayload(cartItem: ICartItem): IGAMRemoveFromCartPayload | undefined {
  if (!cartItem) return;
  try {
    const trackProductData = GAMStorageService.getFromLongTermStore();
    const [item_category, item_category2, item_category3] = getCategoriesTree(cartItem.product.parentCategories);
    const productPrice = cartItem.product.specialPrice || cartItem.product.price;
    const item: IGAMProductPayload = {
      item_name: cartItem.product.displayName,
      item_id: cartItem.product.displayCode,
      price: productPrice,
      item_category,
      item_category2,
      item_category3,
      quantity: cartItem.qty,
      availability: STATUS_LABELS[cartItem.product.inStockStatus],
      item_list_name:
        trackProductData && trackProductData[cartItem.product.id]
          ? trackProductData[cartItem.product.id]?.item_list_name
          : 'product',
      index:
        trackProductData && trackProductData[cartItem.product.id] ? trackProductData[cartItem.product.id]?.index : 1
    };
    return { items: [item], value: cartItem.qty * productPrice, currency: 'UAH' };
  } catch (error) {
    console.error('Error in transformRemoveFromCartPayload:', error);
  }
}

export function transformAddToCartPayload(cartItem: ICartItem): IGAMAddToCartPayload | undefined {
  if (!cartItem) return;
  try {
    const trackProductData = GAMStorageService.getFromStore();
    if (trackProductData && trackProductData[cartItem.product.id]) {
      GAMStorageService.setToLongTermStore({ [cartItem.product.id]: trackProductData[cartItem.product.id] });
    }
    const [item_category, item_category2, item_category3] = getCategoriesTree(cartItem.product.parentCategories);
    const productSum = cartItem.product.specialPrice || cartItem.product.price;
    const items: IGAMProductPayload = {
      item_name: cartItem.product.displayName,
      item_id: cartItem.product.displayCode,
      price: productSum,
      item_category,
      item_category2,
      item_category3,
      quantity: cartItem.qty,
      availability: STATUS_LABELS[cartItem.product.inStockStatus],
      item_list_name:
        trackProductData && trackProductData[cartItem.product.id]
          ? trackProductData[cartItem.product.id]?.item_list_name
          : 'product',
      index:
        trackProductData && trackProductData[cartItem.product.id] ? trackProductData[cartItem.product.id]?.index : 1
    };
    return { items: [items], value: productSum * cartItem.qty, currency: 'UAH' };
  } catch (error) {
    console.error('Error in transformAddToCartPayload:', error);
  }
}

export function transformBeginCheckoutPayload(checkoutData: ICheckoutData): IGAMBeginCheckoutPayload | undefined {
  if (!checkoutData || !checkoutData.items || !Array.isArray(checkoutData.items)) return;

  try {
    const trackProductData = GAMStorageService.getFromLongTermStore();
    const items: IGAMProductPayload[] = checkoutData.items.map((item) => {
      const [item_category, item_category2, item_category3] = getCategoriesTree(item.product.parentCategories);
      return {
        item_name: item.product.displayName,
        item_id: item.product.displayCode,
        price: item.product.specialPrice || item.product.price,
        item_category,
        item_category2,
        item_category3,
        quantity: item.qty,
        availability: STATUS_LABELS[item.product.inStockStatus],
        item_list_name:
          trackProductData && trackProductData[item.product.id]
            ? trackProductData[item.product.id]?.item_list_name
            : 'product',
        index: trackProductData && trackProductData[item.product.id] ? trackProductData[item.product.id]?.index : 1
      };
    });

    return {
      items,
      value: (checkoutData.calculations?.total ?? 0) - (checkoutData.calculations?.shipping ?? 0),
      currency: 'UAH',
      shipping: checkoutData.calculations?.shipping
    };
  } catch (error) {
    console.error('Error in transformBeginCheckoutPayload:', error);
  }
}

export function transformAddPaymentInfoPayload(
  checkoutData: Partial<ICheckoutData>
): IGAMAddPaymentInfoPayload | undefined {
  if (!checkoutData || !checkoutData.items || !Array.isArray(checkoutData.items)) return;

  try {
    const trackProductData = GAMStorageService.getFromLongTermStore();
    const items: IGAMProductPayload[] = checkoutData.items.map((item) => {
      const [item_category, item_category2, item_category3] = getCategoriesTree(item.product.parentCategories);
      return {
        item_name: item.product.displayName,
        item_id: item.product.displayCode,
        price: item.product.specialPrice || item.product.price,
        item_category,
        item_category2,
        item_category3,
        quantity: item.qty,
        availability: STATUS_LABELS[item.product.inStockStatus],
        item_list_name:
          trackProductData && trackProductData[item.product.id]
            ? trackProductData[item.product.id]?.item_list_name
            : 'product',
        index: trackProductData && trackProductData[item.product.id] ? trackProductData[item.product.id]?.index : 1
      };
    });
    const payment_type_id = checkoutData?.payment?.method;
    const payment_type_name = payment_type_id ? checkoutData.options?.payment.methods[payment_type_id]?.name : null;
    return {
      items,
      value: (checkoutData.calculations?.total ?? 0) - (checkoutData.calculations?.shipping ?? 0),
      currency: 'UAH',
      shipping: checkoutData.calculations?.shipping,
      payment_type: payment_type_name,
      approvement: checkoutData.doNotCall
    };
  } catch (error) {
    console.error('Error in transformAddPaymentInfoPayload:', error);
  }
}

export function transformAddShippingInfoPayload(
  checkoutData: ICheckoutData | undefined
): IGAMAddShippingInfoPayload | undefined {
  if (!checkoutData || !checkoutData.items || !Array.isArray(checkoutData.items)) return;

  try {
    const trackProductData = GAMStorageService.getFromLongTermStore();
    const items: IGAMProductPayload[] = checkoutData.items.map((item) => {
      const [item_category, item_category2, item_category3] = getCategoriesTree(item.product.parentCategories);
      return {
        item_name: item.product.displayName,
        item_id: item.product.displayCode,
        price: item.product.specialPrice || item.product.price,
        item_category,
        item_category2,
        item_category3,
        quantity: item.qty,
        availability: STATUS_LABELS[item.product.inStockStatus],
        item_list_name:
          trackProductData && trackProductData[item.product.id]
            ? trackProductData[item.product.id]?.item_list_name
            : 'product',
        index: trackProductData && trackProductData[item.product.id] ? trackProductData[item.product.id]?.index : 1
      };
    });
    const shippingId = checkoutData?.shipping?.method;
    const shippingName = shippingId ? checkoutData.options?.shipping.methods[shippingId]?.description : '';
    const receiver = checkoutData?.shipping?.receiver?.type;
    return {
      items,
      value: (checkoutData.calculations?.total ?? 0) - (checkoutData.calculations?.shipping ?? 0),
      currency: 'UAH',
      shipping: checkoutData.calculations?.shipping,
      shipping_tier: shippingName,
      purpose: receiver === 'client' ? 'я одержувач' : 'на подарунок'
    };
  } catch (error) {
    console.error('Error in transformAddShippingInfoPayload:', error);
  }
}

export const defaultRobotsData = {
  follow: false,
  index: false,
  googleBot: {
    follow: false,
    index: false,
    noimageindex: true
  }
};

export function getRobotsData(
  searchParams: { [key: string]: string | string[] | undefined },
  noIndex?: boolean,
  noFollow?: boolean
) {
  if (
    !searchParams ||
    (searchParams && !Object.keys(searchParams).length) ||
    (searchParams && searchParams.pageNumber && Object.keys(searchParams).length === 1)
  ) {
    return {
      index: !noIndex,
      follow: !noFollow,
      googleBot: {
        follow: !noFollow,
        index: !noIndex,
        noimageindex: noFollow && noIndex
      }
    };
  }
  return defaultRobotsData;
}

export function getCatalogRobotsData(index?: boolean, follow?: boolean) {
  return {
    index,
    follow,
    googleBot: {
      follow,
      index,
      noimageindex: !(follow && index)
    }
  };
}

export function generateMicromarkingBreadcrumbs(links: IBreadCrumbLink[]) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': 'https://flowerpot.ua/',
          name: 'Головна'
        }
      },
      ...links.map((link, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        item: {
          '@id': link.path,
          name: link.label
        }
      }))
    ]
  };
}

export function getOpenGraphData(
  url: string | null,
  metaTitle?: string,
  metaDescription?: string,
  image?: string
): Metadata['openGraph'] {
  return {
    title: metaTitle || 'Flowerpot',
    description: metaDescription || 'Flowerpot',
    url: `${CLIENT_URL}/${url}`,
    images: [image || `${CLIENT_URL}/assets/images/logo-flowerpot-fb.png`],
    siteName: 'Flowerpot - перетворюємо на реальність будь-яку квіткову мрію'
  };
}

function formatAttributes(attributes: IAttributes | undefined): string {
  if (!attributes || Object.keys(attributes).length === 0) {
    return '';
  }

  const attributesArray = Object.values(attributes);
  const countryAttr = attributesArray.find((attr) => attr.alias === 'country') as IAttribute<ICountriesMap>;
  const slicedAttributes = attributesArray.slice(0, 3);

  const characteristics = slicedAttributes
    .map((attr) => `${attr.name}: ${attr.displayValue}${attr.format ? ` ${attr.format}` : ''}`)
    .join('; ');

  return `Характеристики: ${characteristics}${
    countryAttr && countryAttr.value ? `; Виробник: ${countryAttr.value}; ${countryAttr.displayValue}` : ''
  }`;
}

export function generateProductJSONSchema(product: IProduct) {
  const { displayName, attributes, displayCode, price, specialPrice, images, seoKey, inStockStatus } = product;
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: displayName,
    image: images.length ? images[0].full : '',
    description: formatAttributes(attributes),
    sku: displayCode,
    mpn: displayCode,
    brand: {
      '@type': 'Brand',
      name: 'Flowerpot',
      logo: 'https://flowerpot.ua/assets/images/flowerpot_logo.svg'
    },
    offers: {
      '@type': 'Offer',
      url: `${CLIENT_URL}/${seoKey}`,
      priceCurrency: 'UAH',
      price: specialPrice && specialPrice < price ? specialPrice : price,
      priceValidUntil: dayjs().add(7, 'days').format('YYYY-MM-DD'),
      itemCondition: 'http://schema.org/NewCondition',
      availability: STATUS_AVAILABILITY[inStockStatus as keyof typeof STATUS_AVAILABILITY],
      acceptedPaymentMethod: [
        'https://purl.org/goodrelations/v1#Cash',
        'https://purl.org/goodrelations/v1#VISA',
        'https://purl.org/goodrelations/v1#MasterCard'
      ]
    }
  };
}
