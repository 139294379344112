import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/CeraPro-Regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"./fonts/CeraPro-Bold.woff\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"./fonts/CeraPro-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/CeraPro-Light.woff\",\"weight\":\"300\",\"style\":\"normal\"}]}],\"variableName\":\"ceraProFont\"}");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartDataProvider"] */ "/app/src/contexts/cart-data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeliveryAddressProvider"] */ "/app/src/contexts/delivery-address.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainMenuProvider"] */ "/app/src/contexts/main-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileDetectorProvider"] */ "/app/src/contexts/mobile-detector-provider.tsx");
