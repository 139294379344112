import { useState } from 'react';
import Link from 'next/link';

import { Icon } from '@components';
import { appleStoreAppLink, googlePlayAppLink, iOS } from '@lib/constants/environment';
import { shouldShowAppLinkNotification } from '@utils/formatting';

import classes from './AppLinkNotification.module.scss';

const isIOS = iOS(typeof window !== 'undefined' ? window.navigator.userAgent : '');

const AppLinkNotification = () => {
  const [showAppLinkNotification, setShowAppLinkNotification] = useState(shouldShowAppLinkNotification());

  const handleCloseAppLinkNotification = () => {
    setShowAppLinkNotification(false);
    if (typeof window !== 'undefined') {
      localStorage.setItem('appLinkNotification', 'true');
    }
  };

  return showAppLinkNotification ? (
    <div className={classes.wrapper}>
      <span className={classes.label}>Купуйте зручно в нашому додатку</span>
      <Link href={isIOS ? appleStoreAppLink : googlePlayAppLink} className={classes.link}>
        Завантажити
      </Link>
      <Icon onClick={handleCloseAppLinkNotification} type="cross" className={classes.iconClose} size={14} />
    </div>
  ) : null;
};

export { AppLinkNotification };
