import { Dispatch, SetStateAction } from 'react';
import { Dayjs } from 'dayjs';

import { ICheckoutErrors } from '../page-layouts/checkout/checkout-errors';
import { ICartItem } from './cart';
import { IPlaceType } from './locations';
import { IProduct } from './product';

export interface IFinalizeCheckoutResponse {
  orderToken: string;
  orderId?: string;
  paymentRequest: {
    data: {
      data: string;
      signature: string;
    };
    type: string;
  };
}

export type ILocaleField = 'ua' | 'en';

export interface IQuickBuyPayload {
  items: ICartItem[];
  name: string;
  phone: string;
  location: string;
  locale: ILocaleField;
}

export interface IQuickBuyProps {
  product?: IProduct;
  qty?: number;
  customer?: ICustomer;
  cartItems?: ICartItem[];
  onOpenModal?: (cartItem: ICartItem) => void;
}

export interface IQuickBuyFormValues {
  name: string;
  phone: string;
}

export interface ILocationDeliveryDay {
  hasDelivery: boolean;
  deliveryTimes: string[];
  deliveryTimeSlots: string[];
}

export interface ILocationDelivery {
  monday: ILocationDeliveryDay;
  tuesday: ILocationDeliveryDay;
  wednesday: ILocationDeliveryDay;
  thursday: ILocationDeliveryDay;
  friday: ILocationDeliveryDay;
  saturday: ILocationDeliveryDay;
  sunday: ILocationDeliveryDay;
}

export interface ILocation {
  city: string;
  delivery: ILocationDelivery;
  id: string;
  isCoveredByDeliveryNetwork: boolean;
  latitude: string;
  longitude: string;
  minOrderAmountForCourier: number;
  region: string;
  shippingPriceExactTime: number;
  shippingPricePerCity: number;
  shippingPriceSmallOrder: number;
  state: string;
}

export interface ICheckoutCustomer {
  phone: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  locationData?: ILocation;
  location?: string;
  anonymous: boolean;
}

export interface IPersonalSataFormValues {
  customer: ICheckoutCustomer;
}

export interface ICheckoutShippingDetails {
  date: string | Dayjs | null;
  street?: string | IPlaceType;
  building: string;
  unit: string;
  npRef: string;
  systemOutletRef: string;
  deliveryTime: string;
  latitude: number;
  longitude: number;
  outletData?: {
    address?: string;
    name?: string;
  };
}

export enum ShippingReceiverType {
  Client = 'client',
  Recipient = 'recipient'
}

export interface ICheckoutShippingReceiver {
  phone: string;
  firstName: string;
  lastName: string;
  notifyOnDelivery: boolean;
  type: ShippingReceiverType;
}

export interface ICheckoutShipping {
  method: string;
  methodName?: string;
  methodDescription?: string;
  details: ICheckoutShippingDetails;
  receiver?: ICheckoutShippingReceiver;
}

export interface ICheckoutPayment {
  method: string | null;
}

export interface IShippingOutletOption {
  id: string;
  isDefault: boolean;
  city: string;
  street: string;
  builtin: number;
  unit: number;
  phone: string;
  latitude: string;
  longitude: string;
  location: ILocation;
  method: string;
}

export interface IShippingSystemOutletOption {
  id: string;
  code: string;
  name: string;
  latitude: string;
  longitude: string;
  location: ILocation;
  address: string;
}

export interface IShippingTimeOption {
  id: string;
  code: string;
  name: string;
  alias: string;
  description: string;
}

export interface IShippingTimeOptions {
  [id: string]: IShippingTimeOption;
}

export interface IBlockingRuleDate {
  [date: string]: {
    disableWholeDay?: boolean;
    // deliveryTimeSlots?: string[];
    // deliveryTimes?: string[];
    // ! TODO back-end should return an array of strings
    deliveryTimeSlots?: { [time: string]: string };
    deliveryTimes?: { [time: string]: string };
  };
}

export interface IBlockingRules {
  [method_id: string]: IBlockingRuleDate;
}

export type IDeliveryMethod = 'selfpickup' | 'delivery' | 'novaposhta';

export interface IShippingMethodOptions {
  [method_id: string]: {
    id: string;
    code: string;
    name: string;
    alias: IDeliveryMethod;
    description: string;
  };
}

export interface IShippingOptions {
  outlets: IShippingOutletOption[];
  systemOutlets: IShippingSystemOutletOption[];
  times: IShippingTimeOptions;
  methods: IShippingMethodOptions;
  exactTimes: IShippingTimeOptions;
  blockingRules?: IBlockingRules;
}

export type IPaymentMethod = 'liqpay' | 'portmone' | string;

export interface IPaymentMethodOption {
  id: string;
  code: IPaymentMethod;
  name: string;
  idDefault: boolean;
}

export interface IPaymentMethodOptions {
  [method_id: string]: IPaymentMethodOption;
}

export interface IPaymentOptions {
  methods: IPaymentMethodOptions;
}

export interface ICheckoutOptions {
  shipping: IShippingOptions;
  payment: IPaymentOptions;
}

export interface IValidationRule {
  valid: boolean;
  errors: ICheckoutErrors;
}

export interface IValidationResults {
  customer: IValidationRule;
  shipping: IValidationRule;
  payment: IValidationRule;
  products: IValidationRule;
  promoCode: IValidationRule;
}

export interface ICalculations {
  discount: number;
  shipping: number;
  total: number;
}

export interface ICheckoutData {
  items: ICartItem[];
  customer: ICheckoutCustomer;
  shipping: ICheckoutShipping;
  payment: ICheckoutPayment;
  doNotCall: boolean;
  notes: string | null;
  options?: ICheckoutOptions;
  validationResults?: IValidationResults;
  locale: ILocaleField;
  calculations?: ICalculations;
  promoCode?: IPromoFormValues;
}

export interface ICheckoutStepError {
  customer?: string;
  shipping?: string;
  payment?: string;
}

export interface ICheckoutContext {
  checkoutData: ICheckoutData | undefined;
  setCheckoutData: Dispatch<SetStateAction<ICheckoutData | undefined>>;
  isLoading: boolean;
  isPending: boolean;
  deliveryMethod: IDeliveryMethod | undefined;
  setDeliveryMethod: Dispatch<SetStateAction<IDeliveryMethod | undefined>>;
  isExactTime: boolean;
  setIsExactTime: Dispatch<SetStateAction<boolean>>;
  validateCheckout: (formData: Partial<ICheckoutData>) => Promise<void | ICheckoutData | undefined>;
  finalizeCheckout: (formData: Partial<ICheckoutData>) => Promise<IFinalizeCheckoutResponse>;
  globalErrorMessage?: string;
  stepErrors: ICheckoutStepError;
  setStepError: Dispatch<SetStateAction<ICheckoutStepError>>;
}

export interface ICustomer {
  name?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export interface IPromoFormValues {
  discountAmount?: number;
  discountPercent?: number;
  code: string;
}
