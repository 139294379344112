'use client';

import { FC, useState } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { Tab, TabPanel, Tabs } from '@components';
import { CatalogProductCard } from '@components/product/CatalogProductCard';
import Carousel from '@components/shared/carousel/Carousel';
import { breakpoints } from '@lib/constants/breakpointsSwiper';
import { GAMStorageService } from '@services/GAMStorageService';
import { IProduct } from '@types';

import classes from './offers.module.scss';

interface IMainOffersProps {
  title: string;
  products: IProduct[] | undefined;
  allPath: string;
  withoutMoreButton?: boolean;
  wrapperClassName?: string;
}

const MainOffers: FC<IMainOffersProps> = ({ title, products, withoutMoreButton, wrapperClassName, allPath }) => {
  const [value, setValue] = useState(0);

  if (!products) return null;

  const tabs = Array.from(new Set<string>(products.map((p) => p.catalogCategory))).splice(0, 5) as string[];
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const onCardClick = (id: string, index: number) => {
    const itemListName = `${title} || ${value === 0 ? 'Всі' : tabs[value - 1]}`;
    GAMStorageService.setToStore({ [id]: { index: index + 1, item_list_name: itemListName } });
  };

  const activeCategorySeoKey =
    value === 0 ? allPath : products.find((p) => p.catalogCategory === tabs[value - 1])?.catalogCategorySeoKey;

  const productCards = products
    .filter((p) => (value !== 0 ? p.catalogCategory === tabs[value - 1] : true))
    .map((p, idx) => (
      <CatalogProductCard
        onCardClick={onCardClick}
        key={p.displayCode}
        index={idx}
        item={p}
        containerClassName={classes.productCardContainer}
      />
    ));

  return (
    <div className={clsx(classes.offersWrapper, wrapperClassName)}>
      <article className={classes.offersContainer}>
        <div className={classes.tabsListContainer}>
          <h2 className={classes.offersTitle}>{title}</h2>
          <div className={classes.tabsList}>
            <Tabs classNameWrapper={classes.tabsContainer}>
              <Tab label="Всі" index={0} selectedValue={value} onClick={handleChange} />
              {tabs.map((t, idx) => (
                <Tab key={idx} label={t} index={idx + 1} selectedValue={value} onClick={handleChange} />
              ))}
            </Tabs>
          </div>
        </div>
        {tabs
          ? ['all', ...tabs].map((t, idx) => (
              <TabPanel key={idx} value={value} index={idx} className={classes.tabContainer}>
                <Carousel productCards={productCards} breakpoints={breakpoints} />
              </TabPanel>
            ))
          : null}
        {!withoutMoreButton ? (
          <Link href={`/${activeCategorySeoKey}`} className={classes.showAllBtn}>
            Дивитись всі
          </Link>
        ) : null}
      </article>
    </div>
  );
};

export { MainOffers };
