import { countriesMap, ICountriesMap } from '@lib/countriesMap';
import {
  ICategoryMap,
  IFilterOptions,
  IFilterProductCatalogCategory,
  IFilterProp,
  IFilterPropMap,
  IFiltersJSON
} from '@types';
import {
  ATTRIBUTE_COATING_PATH,
  ATTRIBUTE_COLOR_PATH,
  ATTRIBUTE_COUNTRY_PATH,
  ATTRIBUTE_FLOWER_TYPE_PATH,
  ATTRIBUTE_HEIGHT_PATH,
  ATTRIBUTE_MANUFACTURER_PATH,
  ATTRIBUTE_MATURITY_PATH,
  ATTRIBUTE_PRICE_RANGE_PATH,
  ATTRIBUTE_QUALITY_PATH,
  ATTRIBUTE_SHAPE_PATH,
  ATTRIBUTE_SIZE_PATH,
  FILTER_CATEGORY_PATH
} from './options/constants';

const AVAILABLE_FILTERS = [
  'pageNumber',
  'pageSize',
  'sortBy',
  'sortOrder',
  'instock',
  'product',
  'preorder',
  'supplier',
  'discount',
  'specialOffers',
  'transitOffers',
  'category',
  'country',
  'manufacturer',
  'color',
  'diameter',
  'priceRange',
  'pricerange',
  'flowertype',
  'flowerType',
  'price',
  'height',
  'size',
  'width',
  'coating',
  'shape',
  'length',
  'volume',
  'packing',
  'quality',
  'maturity',
  'weight'
];

export function toNonFiltersQuery(filterParams?: IFiltersJSON, extraQuery?: string): string {
  if (!filterParams || typeof filterParams !== 'object') return '';

  return [
    filterParams?.sortBy ? 'sortBy=' + filterParams?.sortBy : '',
    filterParams?.sortOrder ? 'sortOrder=' + filterParams?.sortOrder : '',
    filterParams?.pageNumber ? 'pageNumber=' + filterParams?.pageNumber : '',
    filterParams?.pageSize ? 'pageSize=' + filterParams?.pageSize : '',
    extraQuery
  ]
    .filter(Boolean)
    .join('&');
}

export function flatCategories(array: IFilterProductCatalogCategory[]): IFilterProductCatalogCategory[] {
  let result: IFilterProductCatalogCategory[] = [];
  array.forEach((item) => {
    result.push(item);
    if (Array.isArray(item.items)) {
      result = result.concat(flatCategories(item.items));
    }
  });
  return result;
}

export function filtersToMap(filters: IFilterProp[]) {
  const filterMap: IFilterPropMap = {};
  filters.forEach((item) => {
    if (!filterMap[item.path]) {
      filterMap[item.path] = [];
    }

    filterMap[item.path].push(item.value);
  });

  return filterMap;
}

export function filterJsonToString(json: IFilterPropMap): string {
  const s: string[] = [];
  Object.keys(json).forEach((itemKey) => {
    const value = json[itemKey];
    s.push(`${itemKey}=${value.join ? value.join(',') : value}`);
  });

  return s.join('&');
}

export function filterStringToJson(str: string, filterOptionsData?: IFilterOptions): IFiltersJSON {
  const array = str.split('&');
  const categoryArray = filterOptionsData?.catalogCategory;

  const categoryMap: ICategoryMap = {};
  if (categoryArray) {
    const flatCategoryArray = flatCategories(categoryArray);
    flatCategoryArray.forEach((item) => {
      categoryMap[item.alias] = { label: item.name, item };
    });
  }

  const arrayRes: IFilterProp[] = [];
  let pageNumber: string | undefined;
  let pageSize: string | undefined;
  let sortBy: string | undefined;
  let sortOrder: string | undefined;
  let product: string | undefined;
  let supplier: string | undefined;
  let discount: string | undefined;
  let specialOffers: string | undefined;
  let transitOffers: string | undefined;
  array.forEach((filter) => {
    const [key, values] = filter.split('=');
    if (!key || !AVAILABLE_FILTERS.includes(key)) {
      return;
    }
    if (key === 'pageNumber') {
      return (pageNumber = values);
    }
    if (key === 'pageSize') {
      return (pageSize = values);
    }
    if (key === 'sortBy') {
      return (sortBy = values);
    }
    if (key === 'sortOrder') {
      return (sortOrder = values);
    }
    if (key === 'product') {
      return (product = values);
    }
    if (key === 'supplier') {
      return (supplier = values);
    }
    if (key === 'discount') {
      return (discount = values);
    }
    if (key === 'specialOffers') {
      return (specialOffers = values);
    }
    if (key === 'transitOffers') {
      return (transitOffers = values);
    }

    if (!values) return;

    values.split(',').forEach((value) => {
      const rule = [ATTRIBUTE_HEIGHT_PATH, ATTRIBUTE_PRICE_RANGE_PATH].indexOf(key) > -1 ? 'range' : 'include';

      const filterItem: IFilterProp = {
        rule,
        path: key,
        value
      };
      if (key === ATTRIBUTE_MATURITY_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_MATURITY_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }
      if (key === ATTRIBUTE_QUALITY_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_QUALITY_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }
      if (key === ATTRIBUTE_SIZE_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_SIZE_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }
      if (key === ATTRIBUTE_SHAPE_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_SHAPE_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }
      if (key === ATTRIBUTE_COATING_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_COATING_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }
      if (key === ATTRIBUTE_COUNTRY_PATH) {
        filterItem.label = (countriesMap[value as ICountriesMap] && countriesMap[value as ICountriesMap].name) || value;
      }

      if (key === ATTRIBUTE_MANUFACTURER_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_MANUFACTURER_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }

      if (key === ATTRIBUTE_COLOR_PATH) {
        filterItem.label =
          filterOptionsData?.attributes
            .find((a) => a.alias === ATTRIBUTE_COLOR_PATH)
            ?.options.find((o) => o.value === value)?.label || value;
      }

      if (key === ATTRIBUTE_FLOWER_TYPE_PATH) {
        filterItem.label = filterOptionsData?.flowerTypes?.find((o) => o.alias === value)?.label || value;
      }

      if (key === FILTER_CATEGORY_PATH && categoryArray) {
        if (categoryMap[value] && categoryMap[value].item.items && categoryMap[value].item.items.length) {
          arrayRes.push({
            rule: 'include',
            path: FILTER_CATEGORY_PATH,
            value: categoryMap[value].item.alias,
            label: categoryMap[value].item.name || categoryMap[value].item.alias
          });
          const allChildren = getAllCategoryChildrenFilters(categoryMap[value].item.items, categoryMap);
          arrayRes.push(...allChildren);
        } else {
          // if no children add particular category
          filterItem.label = (categoryMap[value] && categoryMap[value].label) || value;
          arrayRes.push(filterItem);
        }
        return;
      }
      arrayRes.push(filterItem);
    });
  });

  return {
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    arrayRes,
    product,
    supplier,
    discount,
    specialOffers,
    transitOffers
  };
}

function getAllCategoryChildrenFilters(items: IFilterProductCatalogCategory[], categoryMap: ICategoryMap) {
  const arr: IFilterProp[] = [];

  const filterItem: IFilterProp = {
    rule: 'include',
    path: FILTER_CATEGORY_PATH,
    value: ''
  };

  items.forEach((item) => {
    if (Boolean(item.productsCount)) {
      // check if category is already exist in filters
      if (arr.some((i) => i.value === item.alias)) {
        if (item.items && item.items.length) {
          arr.push(...getAllCategoryChildrenFilters(item.items, categoryMap));
        }

        arr.push({ ...filterItem, value: item.alias, label: categoryMap[item.alias].label });
      }
    }
  });
  return arr;
}