import { CACHE_KEYS, ONE_YEAR_CACHE_TIME } from '@lib/constants/cache-keys';
import { API_URL } from '@lib/constants/environment';
import { ILocation, IPlaceType } from '@types';

// TODO replace with the proper types
export async function getLocations(queryString: string) {
  const res = await fetch(`${API_URL}/location/find`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ q: queryString }),
    cache: 'no-cache'
  });
  return res.json();
}

// * we can't cache this because it is POST request, next cache only GET requests
export async function getLocation(location: ILocation | IPlaceType | null): Promise<ILocation> {
  const res = await fetch(`${API_URL}/location/find-by-geo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(location),
    next: { tags: [CACHE_KEYS.LOCATION_FIND_BY_GEO], revalidate: ONE_YEAR_CACHE_TIME } // 1 year in seconds
  });
  return res.json();
}
