import Link from 'next/link';

import { Icon } from '@components';
import { appleStoreAppLink, googlePlayAppLink } from '@lib/constants/environment';

import classes from './AppLinks.module.scss';

const AppLinks = () => {
  return (
    <div className={classes.container}>
      <span className={classes.label}>Купуйте з мобільного в наших додатках</span>
      <div className={classes.links}>
        <Link href={googlePlayAppLink} target="_blank">
          <Icon type="googlePlay" height={40} width={135} />
        </Link>
        <Link href={appleStoreAppLink} target="_blank">
          <Icon type="appStore" height={40} width={131} />
        </Link>
      </div>
    </div>
  );
};

export { AppLinks };
